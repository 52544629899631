
.dropdown-divider {
    border-top: solid 0.5px #b9b9b9 !important;
}

.page-content {
    min-height: 100vh !important;
}

.thumb-img {
    height: 200px !important;
    object-fit: contain I !important;
}

.divider-1 {
    height: 1px;
    background-color: #dee2e6;
}


.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-50 {
    margin-top: 50px;
}

.mb-10 {
    margin-bottom: 10px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-50 {
    margin-bottom: 50px;
}

.question-table-tag {
    /* background: #2b3a4a;
    border-radius: 5px;
    padding: 2px 10px;
    color: white; */
    background: #d6d6d6;
    border-radius: 5px;
    padding: 2px 10px;
    color: #4b4b4b;
}

/* TREE VIEW CSS START */
/* .rst__lineBlock { */
.rst__node {
    /* height: 40px !important; */
    /* top: 40px !important; */
}
.rstcustom__rowWrapper {
    padding: 5px 10px 5px 0;
}
/* TREE VIEW CSS EMD */


/* CODE EDITOR Z-INDEX ISSUE RESOVLVED BY CSS START */
.DraftEditor-editorContainer {
    z-index: 0 !important;
    padding: 5px 15px !important;
}
/* CODE EDITOR Z-INDEX ISSUE RESOVLVED BY CSS END */